.tab-opp {
    background-color: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    outline: 0 !important;
}

.logoss {
    width: 200px;
    object-fit: cover;
    height: 40px;
}
.logos1 {
    width: 150px;
    object-fit: cover;
    height: 40px;
}

.mobile-tab-ul {
    list-style: none;
    padding: 25px 22px;
    display: flex;
    gap: 35px;
    /* height: 50px; */
    flex-direction: column;
    justify-content: space-between;
}

.mobile-tab-ul > li {
    font-size: 18px;
    text-transform: uppercase;
}

.linko {
    color: white !important;
}

.sc-beqWaB {
    /* background: linear-gradient(190deg, #072342, #588ac0) !important; */
}
.active-link {
    border-bottom: 2px solid blue;
}

/* color: #588ac0;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 35px;
} */

.sub-menu-link {
    text-transform: uppercase;
}
.ul-pros {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 0px 0px 0px;
}
.row11 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.di-12 {
    display: flex;
    gap: 20px;
    align-items: center;
}
.updown-spa > svg {
    font-size: 25px;
}

.bg-img.bg-img-top {
    background-position: center !important;
}
.logii {
    width: 190px;
    padding: 10px 0px;
}
.intro-section {
    background-position: center !important;
}
.inj1 {
    object-fit: cover;
    width: 350px;
    height: 300px;
    border-radius: 10px;
}
.head-tit {
    color: #588ac0;
    font-weight: 700;
    font-size: 25px;
    padding: 15px 0px;
    text-transform: uppercase;
}
.bb-b1 {
    border: 1px solid;
    border-radius: 15px;
    padding: 10px 10px;
}
.bb-b2 {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
}
.bb-b3 {
    display: flex;
    flex-direction: column;
}

.head-pro4 {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 360px;
    padding: 15px;
    display: flex;
    border-radius: 15px;
    gap: 14px;
    flex-direction: column;
}

.head-di1 {
    display: flex;
    border: 1px solid #ada4a4;
    padding: 5px 10px;
    justify-content: space-between;
}
.head-di2 {
    display: flex;
    flex-direction: column;
}
.pre-pre {
    white-space: pre;
}
