/*
-----------------------------------------------------------------------
  Typography CSS
-----------------------------------------------------------------------
*/

body {
  color: $body-color;
  @include font-size($font-size-base);
  font-family: $body-font;
  font-weight: $font-weight-normal;
  letter-spacing: .25px;
  line-height: $body-line-height;
  margin: 0;
  overflow-x: hidden;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $font-weight-bold;
  line-height: 1.1;
  margin-bottom: 10px;
  margin-top: 0;
}

a {
  color: #588ac0;
  text-decoration: none;
  @include transition(.3s);

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    // color: darken(#588ac0, 15%);
    outline: none;
    text-decoration: none;
  }

  img {
    border: none;
  }
}

.btn:focus {
  box-shadow: none;
}

p {
  margin-bottom: 30px;

  &:last-child{
    margin-bottom: 0;
  }
}

:active,
:focus {
  box-shadow: none;
  outline: none !important;
}

::selection {
  background: #588ac0;
  color: $white;
  text-shadow: none;
}

::-moz-selection {
  background: #588ac0; /* Firefox */
  color: $white;
  text-shadow: none;
}

::-webkit-selection {
  background: #588ac0; /* Safari */
  color: $white;
  text-shadow: none;
}

img { max-width: 100%; }

iframe { border: none !important; }

textarea, input {
  &:focus, &:active {
    outline: none;
  }
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

table p {
  margin-bottom: 0;
}